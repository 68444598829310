.header-color {
	background-color: #f5e6d5;
}

.navbar-brand {
	padding: 10px;
}

.nav-link {
	color: #000 !important;
	/* padding: 10px 20px !important; */
	margin-right: 10px !important;
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 500 !important ;
	font-size: 16px !important;
	line-height: 24px !important;
}

.dropdown-menu > .active {
	color: darkblue !important;
	/* border-bottom: 4px solid #0f42a4 !important; */
	border-radius: 10px;
}

.navbar-toggler {
	background-color: #f5e6d5;
}



.btn-icon {
	font-size: 1.5em !important;
	margin: 5px;
	color: #051a52;
}

.nav-footer,
.media {
	color: #051a52 !important;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
}


hr {
	border-top: 0.0625rem solid #000;
}

.blink_me {
	animation: blinker 2s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
.nav-link:hover {
	color: #00D1FF !important;
}

.active {
	color: #00D1FF !important;
}

@media (max-width: 768px) {
	.drop-down {
		margin-bottom: 0;
	}
}
