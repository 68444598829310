.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}

.tabbable-line > .nav-tabs > li > active {
  border: 0;
  margin-right: 0;
  color: #737373;
}

.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open,
.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid rgb(80, 144, 247);
}

.active {
  border: 0;
  background: none !important;
  color: #333333;
}

.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #32465b;
  position: relative;
}

.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333333;
}

.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}

.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.white-text {
  color: #fff !important;
}

.nbtn {
  height: 6rem;
}

.btn-light {
  background: #fff !important;
}

.vigi-card-icon {
  background-color: #FFFFFF;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.vigi-card-icon img {
  height: 30px;
}
.vigi-card-header-text{
  color: #0E347E  !important;
}
