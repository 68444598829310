.eduhero {
  background-image: url(../img/educollect.jpg);
  background-size: cover;
  background-position: center;
}
.laptopbg {
  background-color: #e9fafb;
  background-image: url(../img/laptopbg.svg);
  background-size: cover;
}

.edu-icon {
  margin-bottom: 0;
  vertical-align: middle;
  color: #5E4BCE;
  font-size: 24px;
}
