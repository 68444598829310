/* .header-color {
    background-color: #E8EFFD !important;
}

.navbar-toggler {
    background-color: #E8EFFD;
} */

.hero-page {
  height: 80vh;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 209, 255, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.hero-pages {
  height: 80vh;
  background: rgba(0, 209, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.media-bodys {
  font-size: 1.2em;
}

.contact__us__text{
  color: #0E347E !important
}
.heading__text{
  color: #0E347E !important;
}
