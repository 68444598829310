body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 20px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-toast-notifications__container {
	z-index: 1500 !important;
}

.modal-open[style] {
	padding-right: 0px !important;
}

.modal {
	overflow-y: auto !important;
}

.modal-open {
	overflow: auto !important;
}
.btn-background-color {
	background-color: #1542d0 !important;
	color: #ffffff !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-weight: 600;
	line-height: 1.4;
	color: #03073f;
}
p {
	color: #0a0a0b;
}

.footer__Heading__text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #041644 !important;
}
.footer__link {
	color: #051a52 !important;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 13px !important;
	line-height: 16px !important;
}
.partner__our__partner__text {
	color: #041644 !important;
	padding: 0.5rem !important;
}
.blog__heading__text {
	color: #0d0f11 !important;
}
.blog__header__text {
	color: #b9cff9;
}

@keyframes cookie-display {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.cookie-container {
	box-shadow: 0 7px 30px 5px rgba(150, 170, 180, 0.5) !important;
	padding: 0.5rem 3rem;
	color: #677788 !important;
	background-color: #ffffff !important;
	display: flex;
	align-items: center !important;
	-webkit-animation: cookie-display 1s ease-out;
	-moz-animation: cookie-display 1s ease-out;
	-o-animation: cookie-display 1s ease-out;
	animation: cookie-display 1s ease-out;
}

@media (max-width: 480px) {
	.cookie-container {
		padding: 0;
	}
}
