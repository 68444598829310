.hero-page-about {
	height: 40vh;
	width: 100%;
	background: rgba(0, 209, 255, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.hero-page-about > p {
	text-align: center;
	font-size: large;
}

.abouthero {
	background-size: cover;
	background-image: url(../img/Aboutbg.png);
	background-position: 30%;
}
.top {
	height: 10px;
	width: 100px;
	background: #0e347e !important;
	margin-bottom: 20px;
}

.box {
	position: absolute;
	width: 86.96px;
	height: 84.19px;
	left: 10px;
	bottom: 20%;
	background: #fff8e5;
	border: 8px solid #ffffff;
	box-sizing: border-box;
	border-radius: 4px;
	transform: matrix(-1, 0, 0, 1, 0, 0);
}

.action-box {
	background: #fff8e5;
	border-radius: 8px;
	width: 100%;
	height: 30vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.img-data {
	width: 100%;
}

.about-card-icon {
	background-color: #edebfa;
	width: 64px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.about-card-icon img {
	height: 30px;
}
