.card_container {
    height: 300px;
    max-width: 250px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 1.5rem;
}

.card_group_container {
    padding: 4rem 3rem 2rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card_title {
    font-weight: 600;
    font-size: 15px;
}

.card_content {
    font-size: 13px;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.content_price {
    color: #345dee;
}

.about_points {
    padding-top: 2rem;
    display: flex;
}

.bg-color {
    background-color: #eafbf1;
}

.about_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
}

.about_details h4 {
    margin-bottom: 20px;
}

.about_details p {
    margin-bottom: 1rem;
}

.introduction {
    padding: 7rem 0 16rem;
}

.about-intro-text {
    width: 52%;
}
.about-intro-text h2 {
    font-weight: 600;
    font-size: 48px;
}

.about-intro-text p {
    margin-top: 2rem;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
}

@media screen and (min-width: 420px) and (max-width: 576px) {
    .introduction {
        padding: 7rem 0 18rem;
    }
}

@media screen and (max-width: 350px) {
    .introduction {
        padding: 7rem 0 5rem;
    }
}

@media (min-width: 1024px) and (max-width: 1400px) {
    .introduction {
        background-image: url("../img/about-bg.svg");
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-max-device-pixel-ratio: 1.5) {
    .introduction {
        padding: 8rem 0 6rem;
        /* height: 100vh; */
    }
}

@media (max-width: 576px) {
    .introduction {
        padding: 7rem 0 4rem;
    }
    .introduction h2 {
        font-size: 32px;
    }

    .introduction p {
        font-size: 14px;
        line-height: 28px;
    }
    .about-intro-text {
        min-width: 100%;
    }
}

@media (max-width: 990px) {
    .about-intro-text {
        width: 100%;
    }
}
